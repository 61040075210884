<template>
  <footer class="sticky z-20 flex-col px-4 bg-black border-t border-gray-300 border-solid main-footer">
    <nav
      :class="{'lg:flex hidden': $route.meta.footerMobile === true}"
      class="container flex flex-wrap justify-between py-16 m-auto main-footer__sitemap md:flex-no-wrap"
    >
      <div class="w-full mb-0 text-center text-white md:w-128 md:text-left md:mb-0">
        <!-- <a
          class="inline-block main-footer__logo"
          href="/"
          title="Café - Inteligência imobiliária logo"
        >
          <img :src="logonovo" id="logo__symbol_rodape" />
        </a> -->
        <!-- classe hidden ocultava os telefones na versão mobile -->
        <h1 class="titulo-no-rodape-novahome">Fale direto em nosso whatsapp:</h1>
        <div class="my-20">
          <a href="https://api.whatsapp.com/send/?phone=5555999128466" target="_blank">
            <p class="mt-5 mb-5 text-xs md:block" style="position: absolute;left: 0; right: 0;top: 100px">
              <font-awesome-icon :icon="['fab', 'whatsapp']" style="color: black; font-size: 22px; background-color: green;padding: 2px;width: 56px;height: 56px;margin-right:-3px;border-radius: 5px;" />
              <span class="" style="border: 3px solid green; padding: 5px 20px 5px 10px; font-size: 20px;position: relative;top: -17px;font-weight: bold;color:green;border-top-right-radius: 20px;border-bottom-right-radius: 20px;">(55) 99912 8466</span>
            </p>
          </a>
        </div>
        <p class="mt-0 text-xs md:block">
          <span class="font-rodape-novahome">Rua Silva Jardim, 1417, Centro, Santa Maria/RS</span>
        </p>
        <p class="mt-0 text-xs md:block">
          <span class="font-rodape-novahome">R. 3300, nº 341, Sala 12, Balneário Camboriú/SC</span>
        </p>
        <p class="mt-0 text-xs md:block">
          <span class="font-rodape-novahome">Café Negócios Imobiliários Ltda. * Creci 24.707J *</span>
        </p>
      </div>
    </nav>
    <cookie-law theme="dark-lime" buttonText="Prosseguir" style="text-align: center !important;padding: 1% 30% !important;">
      <div slot="message">
        <!-- Here is my message for more info <router-link :to="legal-notes">Click here</router-link> -->
        Utilizamos cookies para melhorar a sua experiência.
      </div>
    </cookie-law>
  </footer>
</template>

<script>
// import CInput from "./UI/CInput.vue"
import { required } from "vuelidate/lib/validators"
import axios from "axios"
import CookieLaw from "vue-cookie-law"
// import { required } from "vuelidate/lib/validators"
export default {
  components: {
    // CInput,
    CookieLaw
  },
  data() {
    return {
      imagemdecomprar: require("../assets/img/home.svg"),
      imagemdealugar: require("../assets/img/icon-whatsapp-png-27.jpg"),
      mostraform: true,
      whatscontato: "Contato",
      whatsfone: "(55) 99912-8466",
      whatssend: "55999128466",
      whatsfoto: require("../assets/img/icon-whatsapp-png-27.jpg"),
      whatsfotopermanente: require("../assets/img/icon-whatsapp-png-27.jpg"),
      wtvenda: {
        whatsnome: "Vendas",
        whatsfone: "(55) 99912-8466",
        whatsfoto: require("../assets/img/cafeimobiliariafundobranco.png"),
        whatssend: "5599128466"
      },
      wtloca1: {
        whatsnome: "Rafael Castro",
        whatsfone: "(55) 98125-0745",
        whatsfoto: require("../assets/img/rafael.png"),
        whatssend: "5581250745"
      },
      wtloca2: {
        whatsnome: "Marciane Pereira",
        whatsfone: "(55) 98127-4958",
        whatsfoto: require("../assets/img/marciane.png"),
        whatssend: "5581274958"
      },
      wtloca3: {
        whatsnome: "Carol",
        whatsfone: "(55) 98127-4958",
        whatsfoto: require("../assets/img/wh_carol.png"),
        whatssend: "5581274958"
      },
      wtloca4: {
        whatsnome: "Gabriella",
        whatsfone: "(55) 98125-7650",
        whatsfoto: require("../assets/img/wh_gabriela.png"),
        whatssend: "5581257650"
      },
      ativowhats: true,
      isTouched: false,
      whatsform: {
        nome: "",
        phone: "",
        // mail: "",
        comentarios: "Gostaria de ser atendido(a)."
      },
      logonovo: require("../assets/img/logos/3.png")
    }
  },
  computed: {
    urlcomplementos() {
      var retorno = ""
      if (this.$route.query.utm_source) {
        retorno += "?utm_source=" + this.$route.query.utm_source
        if (this.$route.query.utm_medium) {
          retorno += "&utm_medium=" + this.$route.query.utm_medium
        }
        if (this.$route.query.utm_campaign) {
          retorno += "&utm_campaign=" + this.$route.query.utm_campaign
        }
        if (this.$route.query.utm_id) {
          retorno += "&utm_id=" + this.$route.query.utm_id
        }
        if (this.$route.query.utm_term) {
          retorno += "&utm_term=" + this.$route.query.utm_term
        }
        if (this.$route.query.utm_content) {
          retorno += "&utm_content=" + this.$route.query.utm_content
        }
        return retorno
      } else {
        return ""
      }
    }
  },
  methods: {
    determinacontatovenda () {
      // console.log("determinacontatovenda")
      this.mostraform = true
      this.whatscontato = this.wtvenda.whatsnome
      this.whatsfone = this.wtvenda.whatsfone
      this.whatsfoto = this.wtvenda.whatsfoto
      this.whatssend = this.wtvenda.whatssend
    },
    determinacontatoalug1 () {
      // console.log("determinacontatoloc1")
      this.mostraform = true
      this.whatscontato = this.wtloca1.whatsnome
      this.whatsfone = this.wtloca1.whatsfone
      this.whatsfoto = this.wtloca1.whatsfoto
      this.whatssend = this.wtloca1.whatssend
    },
    determinacontatoalug2 () {
      // console.log("determinacontatoloc2")
      this.mostraform = true
      this.whatscontato = this.wtloca2.whatsnome
      this.whatsfone = this.wtloca2.whatsfone
      this.whatsfoto = this.wtloca2.whatsfoto
      this.whatssend = this.wtloca2.whatssend
    },
    determinacontatoalug3 () {
      // console.log("determinacontatoloc2")
      this.mostraform = true
      this.whatscontato = this.wtloca3.whatsnome
      this.whatsfone = this.wtloca3.whatsfone
      this.whatsfoto = this.wtloca3.whatsfoto
      this.whatssend = this.wtloca3.whatssend
    },
    determinacontatoalug4 () {
      // console.log("determinacontatoloc2")
      this.mostraform = true
      this.whatscontato = this.wtloca4.whatsnome
      this.whatsfone = this.wtloca4.whatsfone
      this.whatsfoto = this.wtloca4.whatsfoto
      this.whatssend = this.wtloca4.whatssend
    },
    clickMainBtn: function () {
      // Alteração de direcionar DIRETO pro formulário de envio
      this.determinacontatovenda()
      this.$modal.show("modalwhats")
      // this.$modal.show("whatsModal")
      this.mostraform = true
      this.ativowhats = false
    },
    ativaWhats: function () {
      this.ativowhats = true
    },
    enviaWhats: function () {
      // this.isTouched = true
      // if (!this.$v.$invalid) {
      //   var mensagem1 = "Olá, meu nome é " + this.whatsform.nome + " - Telefone: " + this.whatsform.phone + ", " + this.whatsform.comentarios + " | Página: " + window.location
      //   var mensagem = encodeURI(mensagem1)
      //   var win = window.open("https://wa.me/55" + this.whatssend + "?text=" + mensagem, "_blank")
      //   if (win) {
      //     this.isTouched = false
      //     this.whatsform.nome = ""
      //     this.whatsform.phone = ""
      //     this.whatsform.mail = ""
      //     this.whatsform.comentarios = ""
      //     this.$modal.hide("modalwhats")
      //     this.$modal.show("avisoWhats")
      //     this.mostraform = false
      //     if (this.$route.query.utm_medium) {
      //       // console.log("essa parada aqui é o utm_campaign: " + this.$route.query.utm_campaign)
      //       axios.post(
      //         "https://www.cafeimobiliaria.com.br/api/registrador.php", JSON.stringify({
      //           fonte: window.location.href,
      //           formulario: "Contato-Whatsapp",
      //           utm_medium: this.$route.query.utm_medium,
      //           utm_source: this.$route.query.utm_source,
      //           utm_campaign: this.$route.query.utm_campaign
      //         }))
      //     }
      //   }
      // }
      if (!this.$v.$invalid) {
        var mensagem1 = "Olá, meu nome é " + this.whatsform.nome + " - Telefone: " + this.whatsform.phone + ", " + this.whatsform.comentarios + " | Página: " + window.location
        // var mensagem1 = "Olá, gostaria de mais informações | Página: " + window.location
        var mensagem = encodeURI(mensagem1)
        // console.log(mensagem)
        var win = window.open("https://wa.me/55" + this.whatssend + "?text=" + mensagem, "_blank")
        if (win) {
          if (this.$route.query.utm_medium) {
            this.mandaprosleds("Whats")
          }
        }
      } else {
        alert("Campos requiridos: Nome e Telefone")
      }
    },
    enviaWhats2: function () {
      var mensagem1 = "Olá, gostaria de mais informações | Página: " + window.location
      var mensagem = encodeURI(mensagem1)
      var win = window.open("https://wa.me/555581274958?text=" + mensagem, "_blank")
      if (win) {
        if (this.$route.query.utm_medium) {
          axios.post(
            "https://www.cafeimobiliaria.com.br/api/registrador.php", JSON.stringify({
              fonte: window.location.href,
              formulario: "Contato-Whatsapp",
              utm_medium: this.$route.query.utm_medium,
              utm_source: this.$route.query.utm_source,
              utm_campaign: this.$route.query.utm_campaign,
              utm_id: this.$route.query.utm_id,
              utm_term: this.$route.query.utm_term,
              utm_content: this.$route.query.utm_content
            }))
        }
      }
    },
    currentDateTime() {
      const current = new Date()
      const date = current.getFullYear() + "-" + (current.getMonth() + 1) + "-" + current.getDate()
      const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds()
      const dateTime = date + " " + time
      return dateTime
    },
    mandaprosleds(form) {
      let formData = new FormData()
      formData.append("fonte", window.location.href)
      formData.append("formulario", "Form. " + form)
      formData.append("utm_medium", this.$route.query.utm_medium)
      formData.append("utm_source", this.$route.query.utm_source)
      formData.append("utm_campaign", this.$route.query.utm_campaign)
      formData.append("utm_id", this.$route.query.utm_id)
      formData.append("utm_term", this.$route.query.utm_term)
      formData.append("utm_content", this.$route.query.utm_content)
      formData.append("data", this.currentDateTime())
      formData.append("obs", "NC")
      axios.post("https://www.cafeimobiliaria.com.br/sistema/api/contatos/create", formData)
    }
  },
  validations: {
    whatsform: {
      nome: { required },
      phone: { required }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    // if (this.$route.query.utm_medium) {
    //   axios.post(
    //     "https://www.cafeimobiliaria.com.br/api/registrador.php", JSON.stringify({
    //       fonte: window.location.href,
    //       formulario: "Contato-Whatsapp",
    //       utm_medium: this.$route.query.utm_medium,
    //       utm_source: this.$route.query.utm_source,
    //       utm_campaign: this.$route.query.utm_campaign,
    //       utm_id: this.$route.query.utm_id,
    //       utm_term: this.$route.query.utm_term,
    //       utm_content: this.$route.query.utm_content
    //     }))
    // }
    // mandaprosleds(corretor, form) {
    // }
  }
}
</script>

<style lang="postcss">
.main-footer {
  &__logo {
    svg {
      width: 140px;
      color: #673c29;
    }
  }
  &__sitemap {
    h3 {
      @apply text-gray-700;
    }

    .sitemap__categories {
      @apply flex flex-col;
    }
    .c-link--secondary {
      @apply text-sm text-gray-600;

      & + a {
        @apply mt-2;
      }
    }
  }

  &__bottom-bar {
    @apply px-4;
    a + a {
      @apply ml-2;
    }
  }
}
.bwhats-main-content {
  right: 5% !important;
  top: 100px !important;
  position: fixed;
  z-index: 10000;
  margin-bottom: 30px !important;
  display: none !important; /* Ativa desativa o botão no site */
}
.bwhats {
  background-color: #128C7E;
  border: 4px solid #128C7E;
  border-radius: 50px;
}
/*
.bwhats {
  width: 65px !important;
  height: 65px !important;
  font-size: 22px;
  -webkit-animation: bounce .6s infinite alternate;
  -moz-animation: bounce .6s infinite alternate;
  animation: bounce .6s infinite alternate;
}
*/
#whatsModal {
  width: 100%;
}
#whatsModal .c-input__field-wrapper {
    /*border-radius: 20px !important;*/
    /*background-color: red;*/
    width: 87% !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border: 1px solid #34af23 !important;
    height: 34px !important;
    float: left !important;
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
#whatsModal textarea  {
  width: 87% !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  border: 1px solid #34af23 !important;
  height: 75px !important;
  float: left !important;
  border-left: none !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
#whatsModal .c-input__field-wrapper input:focus  {
    /*border-radius: 20px !important;*/
    /*background-color: red;*/
    border: none !important;
    height: 34px !important;
    float: left !important;
    border-left: none !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
#whatsModal input:focus, #whatsModal textarea:focus {
    border: 1px solid #34af23;
}
#whatsModal .input-group-addon {
    border: 1px solid #34af23;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    color: #34af23;
    float: left;
    height: 34px;
    padding: 5px;
    padding-left: 10px;
    width: 35px;
}
#whatsModal .input-group {
    position: relative;
    margin-top: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.balao-whats {
    position: fixed;
    z-index: 99999;
    background: #128c7e;
    padding: 8px;
    border-radius: 15px;
    right: 3% !important;
    bottom: 24% !important;
    color: #fff;
    padding-bottom: 4px;
    font-size: 15px;
    text-align: center;
}
.balao-whats label{
    text-align: center;
}
@media (max-width: 768px) {
  .balao-whats {
    display: none;
  }
}
.btn-whats {
    color: #fff;
    background-color: #128c7e;
    border-color: #128c7e;
    padding: 10px 20px 10px 20px;
    float: right;
    text-align: center;
    border: 1px solid green;
}
.btn-whats:hover {
    color: #fff !important;
    background-color: #127a8c !important;
    border-color: #127a8c !important;
    padding: 10px 20px 10px 20px !important;
    float: right !important;
    text-align: center !important;
    border: 1px solid green !important;
}
.btn-whats-2 {
    color: #fff;
    background-color: #128c7e;
    border-color: #128c7e;
    padding: 7px 20px 7px 20px;
    float: left;
    text-align: center;
    border: 1px solid green;
}
.btn-whats-2:hover {
    color: #fff !important;
    background-color: #127a8c !important;
    border-color: #127a8c !important;
    padding: 7px 20px 7px 20px !important;
    float: left !important;
    text-align: center !important;
    border: 1px solid green !important;
}
/*
@-webkit-keyframes bounce {
  to { -webkit-transform: scale(1.2); }
}
@-moz-keyframes bounce {
  to { -moz-transform: scale(1.2); }
}
@keyframes bounce {
  to { transform: scale(1.2); }
}
*/
.whats-conversas {
  width: 94%;margin: auto;padding: 5px; margin-bottom: 5px; margin-top: 15px; border: 1px solid lightgray;height: 115px;border-radius: 10px;
  -webkit-box-shadow: 4px 4px 4px 0px rgba(214,214,214,1);
  -moz-box-shadow: 4px 4px 4px 0px rgba(214,214,214,1);
  box-shadow: 4px 4px 4px 0px rgba(214,214,214,1);
}
.whats-conversas-2 {
  width: 94%;margin: auto;padding: 5px; margin-bottom: 5px; margin-top: 15px; border: 1px solid lightgray;height: 240px;border-radius: 10px;
  -webkit-box-shadow: 4px 4px 4px 0px rgba(214,214,214,1);
  -moz-box-shadow: 4px 4px 4px 0px rgba(214,214,214,1);
  box-shadow: 4px 4px 4px 0px rgba(214,214,214,1);
}

.whats-conversas div img {
  /* border: 1px solid black; */
  /* border: 1px solid black;
  border-radius: 50%; */
}
#logo__symbol_rodape {
  width: 150px;
}
.btn-whats-expandido {
    /* color: #fff; */
    /* background-color: #128c7e; */
    border-color: #128c7e;
    padding: 10px 20px 10px 20px;
    float: right;
    text-align: center;
    border: 1px solid green;
    height: 120px;
}
.btn-whats-expandido:hover {
    /* color: #fff !important; */
    /* background-color: #127a8c !important; */
    border-color: #127a8c !important;
    padding: 10px 20px 10px 20px !important;
    float: right !important;
    text-align: center !important;
    border: 1px solid green !important;
    height: 120px;
}
.font-rodape-novahome {
  font-size: 14px;
  line-height: 37px;
  color: #f0f0f0;
  font-weight: 400;
  font-family: "Open Sans";
}
.titulo-no-rodape-novahome {
  font-size: 20px;
  line-height: 50px;
  color: #f0f0f0;
  font-weight: 700;
  /* font-family: "Open Sans"; */
}
</style>
